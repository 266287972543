import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <div style={{
      textAlign: 'center'
    }}>
      <div>© Sarthak Narayan | {new Date().getFullYear()} </div>
    </div>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      